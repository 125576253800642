<body data-spy="scroll" data-target="#menu">
    <div id="home">
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img src="../../assets/images/logo.jpeg" class="rounded-circle"></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu">
                    <span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="menu">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#about">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#members">Members</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#events">News and Events</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#members">Partners</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#members">Donate</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <!--landing-->
        <div class="landing">
            <div class="home-wrap">
                <div class="home-inner"></div>
            </div>
        </div>
        <div class="caption center-block text-center">
            <h3>Voice of the voiceless</h3>
            <a class="btn btn-outline-light" href="#about">About us</a>
        </div>
    </div>

    <!--about section-->
    <div id="about" class="jumbotron">
        <h3 class="heading">About Us</h3>
        <div class="row">
            <div class="col-md-4 text-center">
                <h4>Vision</h4>
                <p>Empowerment of young girls an women so as to walk in the fullness of their potential</p>
            </div>
            <div class="col-md-4 text-center">
                <h4>Mission</h4>
                <p>Strategically positioning young girls and women so as to articulate their individual rights</p>
            </div>
            <div class="col-md-4 text-center">
                <h4>Core-values</h4>
                <p>Respect, Transparency, Integrity, Diversity, Inclusivity</p>
            </div>
        </div>
    </div>
    <!---end of jumbotron-->
    <!--portifolio-->
    <div id="events">
        <div class="container-fluid">
            <h3 class="heading">News and Events</h3>
            <div class="row no-padding">
                <div class="col-md-6">
                    <img class="portifolio" src="../../assets/images/events3.jpeg" width="650px">
                </div>
                <div class="col-md-6">
                    <img class="portifolio" src="../../assets/images/events2.jpeg" width="650px">
                </div>
                <div class="col-md-6">
                    <img class="portifolio" src="../../assets/images/events1.jpeg" width="650px">
                </div>
                <div class="col-md-6">
                    <img class="portifolio" src="../../assets/images/events4.jpeg" width="650px">
                </div>
            </div>
        </div>
    </div>
    <!--END OF PORTIFOLIO-->

    <!--testimonmials-->
    <div id="members">
        <h3 class="heading">Our Staff Members</h3>
        <div class="row padding">
            <div class="col-md-4">
                <div class="card text-center">
                    <img class="car d-img-top" src="../../assets/images/director.jpeg" alt="" height="400px">
                    <div class="body">
                        <h4>Tatenda Maposa</h4>
                        <p>Executive Director</p>
                        Contact: + 263 xxxxxxxx<br>
                        Email: gmail@gmail
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card text-center">
                    <img class="card-img-top" src="../../assets/images/programmes.jpeg" alt="" height="400px">
                    <div class="body">
                        <h4>Ennie Tafadzwa Somerai</h4>
                        <p>Programs officer</p>
                        Contact: + 263 xxxxxxxx<br>
                        Email: gmail@gmail
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card text-center">
                    <img class="card-img-top" src="../../assets/images/finance.jpeg" alt="" height="400px">
                    <div class="body">
                        <h4>Vimbayi Manhembe</h4>
                        <p>Finance and Administration</p>
                        Contact: + 263 xxxxxxxx<br>
                        Email: gmail@gmail
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Contact-->
    <div id="contact">
        <div class="container-fluid footer">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 text-center">
                    <strong>Our Location</strong>
                    <p>179 Robertson Street, FBC Building,<br> Masvingo<br> Zimbabwe</p>
                    <strong>Contact info</strong>
                    <p>email : <a>gcezimbabwe2016@gmail.com</a></p>
                    <p>phone : +263 73 496 9250 / +263 77 952 6103</p>
                    <strong> @GCEZ 2020</strong>
                </div>
            </div>
        </div>
    </div>
</body>